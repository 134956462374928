import {
  getRequest,
  // getRequest,
  postRequest,
  // putRequest,
  // deleteRequest,
  postRequestFormData,
  postRequestNoFormData,
  putRequest,
  deleteRequest,
} from "../coreFIles/helper";

export const LoginAction = (data) => {
  return postRequest("login", data).then((res) => {
    return res.data;
  });
};

/// Category
//getUsdcPrice

export const getUsdcPrice = async (data) => {
  return postRequest("getUsdcPrice", {
    amount: 1,
  }).then((res) => {
    return res.data;
  });
};

export const getCategoryListAction = (data) => {
  return postRequest("getCategoryList", data).then((res) => {
    return res.data;
  });
};
export const addCategoryAction = (data) => {
  return postRequest("addCategory", data).then((res) => {
    return res.data;
  });
};

export const getCategoryDetailAction = (data) => {
  return postRequest("getCategoryDetail", data).then((res) => {
    return res.data;
  });
};
export const deleteCategoryAction = (data) => {
  return postRequest("deleteCategory", data).then((res) => {
    return res.data;
  });
};
export const editCategoryAction = (data) => {
  return postRequest("editCategory", data).then((res) => {
    return res.data;
  });
};

//attributes

export const getAttributesListAction = (data) => {
  return postRequest("getAttributesList", data).then((res) => {
    return res.data;
  });
};
export const addAttributeAction = (data) => {
  return postRequest("addAttribute", data).then((res) => {
    return res.data;
  });
};

export const getAttributebyidListAction = (data) => {
  return postRequest("getAttributeByid", data).then((res) => {
    return res.data;
  });
};
export const deleteAttributeAction = (data) => {
  return postRequest("deleteAttribute", data).then((res) => {
    return res.data;
  });
};
export const getAttributeDetailAction = (data) => {
  return postRequest("getAttributeDetail", data).then((res) => {
    return res.data;
  });
};
export const editAttributeAction = (data) => {
  return postRequest("editAttribute", data).then((res) => {
    return res.data;
  });
};

export const changePasswordAction = (data) => {
  return postRequest("changePassword", data).then((res) => {
    return res.data;
  });
};

/// Items

export const getItemListAction = (page = 0, limit =10000, filters = "") => {
  return getRequest(`getItemList?page=${page}&limit=${limit}${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const addItemAction = (data) => {
  return postRequestNoFormData("addItem", data).then((res) => {
    return res.data;
  });
};

export const getItemDetailAction = (data) => {
  return postRequest("getItemDetail", data).then((res) => {
    return res.data;
  });
};

export const getuserguideAction = (data) => {
  return postRequest("getuserguide", data).then((res) => {
    return res.data;
  });
};

export const addUserGuideAction = (data) => {
  return postRequest("addUserGuide", data).then((res) => {
    return res.data;
  });
};

export const editUserGuideAction = (data) => {
  return postRequest("editUserGuide", data).then((res) => {
    return res.data;
  });
};

export const deleteUserGuideAction = (data) => {
  return postRequest("deleteUserGuide", data).then((res) => {
    return res.data;
  });
};

export const getexpertlistAction = (data) => {
  return postRequest("getexpertlist", data).then((res) => {
    return res.data;
  });
};

export const getWithdrawlistAction = (data) => {
  return postRequest("getWithdrawlist", data).then((res) => {
    return res.data;
  });
};

export const getRefundslistAction = () => {
  return postRequest("getRefundsList").then((res) => {
    return res.data;
  });
};

export const updatewithdrawAction = (data) => {
  return postRequest("updatewithdraw", data).then((res) => {
    return res.data;
  });
};

export const sendRevolutTransfer = (data) => {
  return postRequest("sendRevolutTransfer", data).then((res) => {
    return res.data;
  });
};

export const rejectwithdrawAction = (data) => {
  return postRequest("rejectwithdraw", data).then((res) => {
    return res.data;
  });
};

export const addexpertAction = (data) => {
  return postRequestFormData("addexpert", data).then((res) => {
    return res.data;
  });
};

export const editexpertAction = (data) => {
  return postRequestFormData("editexpert", data).then((res) => {
    return res.data;
  });
};

export const deleteexpertAction = (data) => {
  return postRequest("deleteexpert", data).then((res) => {
    return res.data;
  });
};


export const getWithdrawal_requestCountAction = (data) => {
  return postRequest("getWithdrawal_requestCount", data).then((res) => {
    return res.data;
  });
};


export const updateWithdrawal_requestAction = (data) => {
  return postRequest("updateWithdrawal_request", data).then((res) => {
    return res.data;
  });
};

export const getnotificationAction = (pageIndex = 0, pageSize = 10, filters = "") => {
  return postRequest(`getnotificationV2?page=${pageIndex}&limit=${pageSize}${filters ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};
export const updatenotificationAction = (data) => {
  return postRequest("updatenotification", data).then((res) => {
    return res.data;
  });
};
export const addnotificationAction = (data) => {
  return postRequest("addNotification", data).then((res) => {
    return res.data;
  });
};
export const addnotificationtouserAction = (data) => {
  return postRequest("addnotificationtouser", data).then((res) => {
    return res.data;
  });
};
export const updatenotificationstatusAction = (data) => {
  return postRequest("updatenotificationstatus", data).then((res) => {
    return res.data;
  });
};
export const updateanswerAction = (data) => {
  return postRequest("updateanswer", data).then((res) => {
    return res.data;
  });
};
export const editItemAction = (data) => {
  return postRequestNoFormData("editItem", data).then((res) => {
    return res.data;
  });
};
export const deleteItemAction = (data) => {
  return postRequest("deleteItem", data).then((res) => {
    return res.data;
  });
};

export const refundItemAction = (data) => {
  return postRequest("refundItem", data).then((res) => {
    return res.data;
  });
};

export const getAlertRequestListAction = (data) => {
  return postRequest("getAlert_RequestList", data).then((res) => {
    return res.data;
  });
};

export const deleteAlertRequestAction = (data) => {
  return postRequest("Deletealertrequest", data).then((res) => {
    return res.data;
  });
};

export const getTransactionListAction = (data) => {
  return postRequest("getTransactionList", data).then((res) => {
    return res.data;
  });
};

export const getTransactionsV2Action = (page, limit, filters) => {
  return postRequest(`getTransactionsPaginated?page=${page}&limit=${limit}${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const getAllTransactions = (filters) => {
  return postRequest(`getAllTransactions?${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const getDailyTransactionsByItem = () => {
  return getRequest(`downloadDailyTransactions`).then((res) => {
    return res.data;
  });
};

export const getGiftCardsAction = (data) => {
  return getRequest("getGiftCards", data).then((res) => {
    return res.data;
  });
};

export const getSellOrdersAction = (data) => {
  return postRequest("getSellOrders", data).then((res) => {
    return res.data;
  });
};

export const getUserInfoAction = (data) => {
  return postRequest("getUserInfo", data).then((res) => {
    return res.data;
  });
};

export const updateTransactionAction = (data) => {
  return postRequest("updateTransaction", data).then((res) => {
    return res.data;
  });
};

export const updateItemOnboardingAction = (data) => {
  return postRequest("updateItemOnboarding", data).then((res) => {
    return res.data;
  });
};

export const updateItemFractionalizeAction = (data) => {
  return postRequest("updateItemFractionalize", data).then((res) => {
    return res.data;
  });
};

export const liquidateItem = (id, data) => {
  return postRequest("liquidateItem/" + id, data).then((res) => {
    return res.data;
  });
};

////  Filter letter
export const getDashboardStatisticsAction = (data) => {
  return postRequest("getDashboardStatistics", data).then((res) => {
    return res.data;
  });
};

export const getUsersListAction = (data) => {
  return postRequest("getUsersList", data).then((res) => {
    return res.data;
  });
};

export const getUsersV2ListAction = (page, limit, filters) => {
  return postRequest(`getUsersPaginated?page=${page}&limit=${limit}${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const getAllUsers = (filters) => {
  return postRequest(`getAllUsers?${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const getUserDetailsByIdAction = (id) => {
  return postRequest(`getUserDetailsById/${id}`).then((res) => {
    return res.data;
  });
};

export const getUsersEmailListAction = (data) => {
  return postRequest("getUsersEmailList", data).then((res) => {
    return res.data;
  });
};

export const userdeletetAction = (data) => {
  return postRequest("DeleteUsers", data).then((res) => {
    return res.data;
  });
};

export const UserBlockAction = (data) => {
  return postRequest("userblock", data).then((res) => {
    return res.data;
  });
};
export const UserUnBlockAction = (data) => {
  return postRequest("userUnblock", data).then((res) => {
    return res.data;
  });
};
export const updatekycapprovalAction = (data) => {
  return postRequest("updatekycapproval", data).then((res) => {
    return res.data;
  });
};
export const rejectkycapprovalAction = (data) => {
  return postRequest("rejectkycapproval", data).then((res) => {
    return res.data;
  });
};
export const getuserDetailsAction = (data) => {
  return postRequest("getuserDetails", data).then((res) => {
    return res.data;
  });
};

export const getUsersLiquidity = (data) => {
  //body: user_id
  return postRequest("getLiquidityUser", data).then((res) => {
    return res.data;
  });
};

export const setUsersLiquidity = (data) => {
  //body: user_id, bonus_liquidity
  return postRequest("addLiquidityBonus", data).then((res) => {
    return res.data;
  });
};

export const getReferrals = (data) => {
  return postRequest("getReferrals", data).then((res) => {
    return res.data;
  });
};

export const getUserActions = async (page, limit, filters) => {
  try {
    return postRequest(`getUserActions?page=${page}&limit=${limit}&filters=${filters}`).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
    return {
      success: false,
      msg: "Something went wrong",
    };
  }
};

export const setReferralBonus = (data) => {
  //body: id
  return postRequest("setReferralBonusReceived", data).then((res) => {
    return res.data;
  });
};

export const getModalPromo = () => {
  return postRequest("getModalPromo").then((res) => {
    return res.data;
  });
};

export const addModalPromo = (data) => {
  return postRequest("addModalPromo", data).then((res) => {
    return res.data;
  });
};

export const deleteModalPromo = () => {
  return postRequest("deleteModalPromo").then((res) => {
    return res.data;
  });
};

export const editModalPromo = (data) => {
  return postRequest("editModalPromo", data).then((res) => {
    return res.data;
  });
};

// Add these new exports at the end of the file

export const getSupplierListAction = (data) => {
  return getRequest("suppliers", data).then((res) => {
    return res.data;
  });
};

export const addSupplierAction = (data) => {
  return postRequest("suppliers", data).then((res) => {
    return res.data;
  });
};

export const editSupplierAction = (data, id) => {
  return postRequest(`suppliers/${id}`, data).then((res) => {
    return res.data;
  });
};

export const deleteSupplierAction = (id) => {
  return postRequest(`suppliers/${id}/delete`).then((res) => {
    return res.data;
  });
};

export const getWarehouseListAction = () => {
  return getRequest('warehouses');
};

export const addWarehouseAction = (data) => {
  return postRequest('warehouses', data);
};

export const editWarehouseAction = (data, id) => {
  return postRequest(`warehouses/${id}`, data);
};

export const deleteWarehouseAction = (id) => {
  return postRequest(`warehouses/${id}/delete`);
};

export const getGiftItemsAction = () => {
  return getRequest("getGiftItems").then((res) => {
    return res.data;
  });
};
// Azione per ottenere la lista dei cashback
export const getCashbackListAction = async (page = 0, limit = 10, filters = "") => {
  return getRequest(`offers/list?page=${page}&limit=${limit}${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};
// Azione per creare un nuovo cashback
export const createCashbackAction = async (data) => {
  return postRequest('offers', data).then((res) => {
    return res.data;
  });
};

// Azione per modificare un cashback
export const editCashbackAction = async (id, data) => {
  return putRequest(`offers/${id}`, data).then((res) => {
    return res.data;
  });
};

// Azione per eliminare un cashback
export const deleteCashbackAction = async (id) => {
  return deleteRequest(`offers/${id}`).then((res) => {
    return res.data;
  });
};

export const searchUsersByPhoneAction = async (phoneNumbers) => {
  return postRequest('users/searchByPhone', { phoneNumbers }).then((res) => {
    return res.data;
  });
};

export const getCountryListAction = () => {
  return postRequest("getCountryList").then((res) => {
    return res.data;
  });
};

export const payItemAction = (id) => {
  return postRequest(`items/${id}/pay`).then((res) => {
    return res.data;
  });
};

export const getReceiptNumberAction = (id) => {
  return getRequest(`items/${id}/receipt-number`).then((res) => {
    return res.data;
  });
};

export const getFavouriteUsersForItemsAction = (itemIds) => {
  return postRequest('items/favourites/users', { itemIds }).then((res) => {
    return res.data;
  });
};

export const editUserDataAction = (data) => {
  return postRequest("editUserData", data).then((res) => {
    return res.data;
  });
};

export const getBankTransfersAction = (page, limit, filters) => {
  return getRequest(`bank-transfers?page=${page}&limit=${limit}${filters !== "" ? `&filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const getAllBankTransfersAction = (filters) => {
  return postRequest(`getAllBankTransfers${filters !== "" ? `?filters=${filters}` : ""}`).then((res) => {
    return res.data;
  });
};

export const approveBankTransferAction = (id) => {
  return postRequest(`bank-transfer/${id}/approve`).then((res) => {
    return res.data;
  });
};

export const getItemLotsByItemIdAction = (itemId) => {
  return getRequest(`items/${itemId}/lots`).then((res) => {
    return res.data;
  });
};

export const createItemLotAction = (data) => {
  return postRequestFormData("item-lots", data).then((res) => {
    return res.data;
  });
};

export const updateItemLotAction = (id, data) => {
  return postRequestFormData(`item-lots/${id}`, data).then((res) => {
    return res.data;
  });
};

export const deleteItemLotAction = (id) => {
  return deleteRequest(`item-lots/${id}`).then((res) => {
    return res.data;
  });
};

export const payItemLotAction = (id) => {
  return postRequest(`item-lots/${id}/pay`, {}).then((res) => {
    return res.data;
  });
};

export const filterUsersAction = (data) => {
  return postRequest("filter-users", data).then((res) => {
    return res.data;
  });
};

export const createUsersListAction = async (data) => {
  return await postRequest("/lists", data);
};

export const getUsersListsAction = async () => {
  try {
    const response = await getRequest("/lists");
    return response;
  } catch (error) {
    console.error("Errore nel recupero delle liste:", error);
    return {
      success: false,
      msg: "Errore nel recupero delle liste",
    };
  }
};

export const deleteUsersListAction = async (id) => {
  try {
    const response = await deleteRequest(`/lists/${id}`);
    return response;
  } catch (error) {
    console.error("Errore nell'eliminazione della lista:", error);
    return {
      success: false,
      msg: "Errore nell'eliminazione della lista",
    };
  }
};

export const getSettingsAction = () => {
  return getRequest("settings").then((res) => {
    return res.data;
  });
};

export const updateSettingsAction = (data) => {
  return postRequest("settings", data).then((res) => {
    return res.data;
  });
};

