import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { getItemListAction } from "../Action/action";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Swal from "sweetalert2";
import { showPaymentDetailsPopup } from "../utils/paymentPopup";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Pagamenti = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let filters = JSON.stringify(columnFilters ?? []);
      const response = await getItemListAction(pagination.pageIndex, pagination.pageSize, filters);

      if (response.success) {
        let newData = response.data.items.map((item) => {
          return {
            ...item,
            lotsRows: item.lots.map((lot) => ({
              id: lot.id,
              name: lot.description,
              supplier: lot.supplier,
              supplier_type: lot.supplier?.type === "company" ? "Azienda" : "Privato",
              paid: lot.paid,
              sold: item.pre_order_target === 1 ? "Sì" : "No",
              draft: item.draft,
              delivered: lot.warehouse_id != null,
              warehouse: lot.warehouse,
              invoice_date: item.invoice_date,
              first_transaction_date: item.first_transaction_date,
              last_transaction_date: item.last_transaction_date,
              type: "lot",
              price: lot.price,
              total_price: item.paid_amount,
              payment_id: lot.payment_id,
              pre_order_target: item.pre_order_target,
              payment_date: lot.payment_date,

            })),
          };
        });
        setData(newData);
        console.log(newData);
        setRowCount(response.data.totalItems);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  const handlePaymentClick = async (item) => {
    try {
      if (item.lots) {
        const result = await showPaymentDetailsPopup(item.name, item.lots, item.id);
        if (result.success) {
          await fetchData();
        }
      } else {
        Swal.fire({
          title: "Errore",
          text: "Errore nel caricamento dei dettagli del pagamento",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
      Swal.fire({
        title: "Errore",
        text: error.response?.data?.msg || "Errore nel caricamento dei dettagli del pagamento",
        icon: "error",
      });
    }
  };

  const handleCsvDownload = async () => {
    try {
      setCsvLoading(true);
      // Usa i filtri attivi dalla tabella
      let filters = JSON.stringify(columnFilters ?? []);

      // Chiamata API con gli stessi filtri ma senza paginazione
      const response = await getItemListAction(0, 999999, filters);

      if (response.success && response.data.items) {
        const csvData = [
          [
            "ID",
            "Nome",
            "Fornitore",
            "Tipo Fornitore",
            "Venduto",
            "Consegnato",
            "Magazzino",
            "Pagato",
            "Data Fattura",
            "Data Inizio Vendita",
            "Data Chiusura Vendita",
            "Data Pagamento",
            "Stati Pagamento",
            "Prezzo di acquisto",
            "Prezzo di vendita",
            "Stato vendita",
          ],
          ...response.data.items.map((item) => [
            item.id,
            item.name,
            item.lots.map(lot => lot.supplier?.name || "-").join("\n"),
            item.lots.map(lot => lot.supplier?.type === "company" ? "Azienda" : "Privato").join("\n"),
            item.pre_order_target === 1 ? "Sì" : "No",
            item.lots?.length ? item.lots.map(lot => lot.warehouse_id != null ? "Sì" : "No").join("\n") : "No",
            item.lots.map(lot => lot.warehouse?.name || "-").join("\n"),
            item.lots?.length ? item.lots.map(lot => lot.paid ? "Sì" : "No").join("\n") : "No",
            item.lots.map(lot => lot.invoice_date ? moment(lot.invoice_date).format("DD/MM/YYYY") : "-").join("\n"),
            item.first_transaction_date ? moment(item.first_transaction_date).format("DD/MM/YYYY") : "-",
            item.pre_order_target === 1 ? (item.last_transaction_date ? moment(item.last_transaction_date).format("DD/MM/YYYY") : "-") : "-",
            item.lots.map(lot => lot.payment_date ? moment(lot.payment_date).format("DD/MM/YYYY") : "-").join("\n"),
            item.lots?.length ? (item.lots.every(lot => lot.paid === true) ? "Pagato" :
              item.lots.some(lot => lot.paid === true) && item.lots.some(lot => lot.paid === false) ? "Pagato parzialmente" : 
              "Non pagato") : "Non pagato",
            `${item.lots.map(lot => lot.price).reduce((acc, curr) => acc + Number(curr), 0).toFixed(2)}€`,
            `${item.market_value}€`,
            item.draft ? "Bozza" : item.pre_order_target === 0 ? "In vendita" : "Venduto",
          ]),
        ];
        setCsvData(csvData);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      Swal.fire({
        title: "Errore",
        text: "Errore durante l'esportazione dei dati",
        icon: "error",
      });
    } finally {
      setCsvLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID item",
        size: 50,
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: "Nome item",
        enableColumnFilter: true,
      },
      {
        accessorKey: "supplier",
        header: "Fornitore",
        //Cell: ({ row }) => (row.original.supplier ? row.original.supplier?.name : "---"),
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return row.original.supplier ? row.original.supplier?.name : "---";
          }else{
            return (
              <ul className="list-unstyled m-0">
                {row.original.lots.map((lot, index) => (
                  <li key={index}>{lot.supplier ? lot.supplier?.name : "---"}</li>
                ))}
              </ul>
            );
          }
        },
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "supplier_type",
        header: "Tipo fornitore",
        Cell: ({ row }) => (row.original.supplier?.type === "company" ? "Azienda" : row.original.supplier?.type == "private" ? "Privato" : "---"),
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return row.original.supplier ? row.original.supplier?.type === "company" ? "Azienda" : "Privato" : "---";
          }else{
            return (
              <ul className="list-unstyled m-0">
                {row.original.lots.map((lot, index) => (
                  <li key={index}>{lot.supplier ? lot.supplier?.type === "company" ? "Azienda" : "Privato" : "---"}</li>
                ))}
              </ul>
            );
          }
        },
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.supplier?.type === (filterValue === "company");
        },
        filterVariant: "select",
        filterSelectOptions: [
          { value: "company", text: "Azienda" },
          { value: "private", text: "Privato" },
        ],
        enableSorting: false,
      },
      {
        accessorKey: "sold",
        header: "Venduto",
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return "-"
          }else{
            return <span className={`badge ${row.original.pre_order_target === 1 ? "bg-success" : "bg-danger"}`}>{row.original.pre_order_target === 1 ? "Sì" : "No"}</span>;
            
          }
        },
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.pre_order_target === (filterValue === "true");
        },
        filterVariant: "select",
        filterSelectOptions: [
          { value: true, text: "Sì" },
          { value: false, text: "No" },
        ],
        enableSorting: false,
      },
      {
        accessorKey: "draft",
        header: "Bozza",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.draft === (filterValue === "true");
        },
        filterVariant: "select",
        filterSelectOptions: [
          { value: true, text: "Bozza" },
          { value: false, text: "Pubblico" },
        ],
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return "-";
          }else{
            return <span className={`badge ${row.original.draft ? "bg-warning" : "bg-success"}`}>{row.original.draft ? "Bozza" : "Pubblico"}</span>;
          }
        },
      },
      {
        accessorKey: "delivered",
        header: "Consegnato",
        filterVariant: "select",
        filterSelectOptions: [
          { value: true, text: "Sì" },
          { value: false, text: "No" },
        ],
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return <span className={`badge ${row.original.warehouse ? "bg-success" : "bg-danger"}`}>{row.original.warehouse != null ? "Sì" : "No"}</span>;
          } else {
            const allLotsDelivered = row.original.lots.every(lot => lot.warehouse_id != null);
            const allLotsNotDelivered = row.original.lots.every(lot => lot.warehouse_id == null);
            if(allLotsDelivered){
              return <span className={`badge bg-success`}>Sì</span>;
            }else if(allLotsNotDelivered){
              return <span className={`badge bg-danger`}>No</span>;
            }else{
              return <span className={`badge bg-warning`}>Parzialmente</span>;
            }
          }
        },
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.delivered === (filterValue === "true");
        },
        enableSorting: false,
      },
      {
        accessorKey: "warehouse",
        header: "Magazzino",
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return row.original.warehouse ? row.original.warehouse?.name : "---";
          }else{
            return (
              <ul className="list-unstyled m-0">
                {row.original.lots.map((lot, index) => (
                  <li key={index}>{lot.warehouse ? lot.warehouse?.name : "---"}</li>
                ))}
              </ul>
            );
          }
        },
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "paid",
        header: "Pagato",
        filterVariant: "select",
        filterSelectOptions: [
          { value: true, text: "Sì" },
          { value: false, text: "No" },
        ],
        Cell: ({ row }) => {
            if(row.original.type == "lot"){
              return <span className={`badge ${!row.original.paid && row.original.payment_id ? "bg-warning" : row.original.paid ? "bg-success" : "bg-danger"}`}>{!row.original.paid && row.original.payment_id ? "Pending" : row.original.paid ? "Pagato" : "Non pagato"}</span>;
            }else{
              const allLotsPaid = row.original.lots.every(lot => lot.paid === true);
              const allLotsUnpaid = row.original.lots.every(lot => lot.paid === false);
              if(allLotsPaid) {
                return <span className="badge bg-success">Pagato</span>;
              } else if(allLotsUnpaid) {
                return <span className="badge bg-danger">No</span>;
              } else {
                return <span className="badge bg-warning">Pagato parzialmente</span>;
              }
            }
          
        },
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.paid === (filterValue === "true");
        },
      },
      {
        header: "Prezzo di acquisto",
        enableColumnFilter: true,
        //Cell: ({ row }) => row.original.paid_amount ? `${row.original.paid_amount}€` : "-------",
        Cell: ({ row }) => {
          if(row.original.type === "lot"){
            return row.original.price ? `${row.original.price}€` : "-------";
          }else{
            return row.original.lots.reduce((acc, lot) => acc + Number(lot.price), 0).toFixed(2) + "€";
          }
        },
        size: 100, //medium column
      },
      {
        accessorKey: "market_value",
        header: "Prezzo di vendita",
        enableColumnFilter: true,
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            
          }else{
            return `${row.original.market_value}€`
          }
        },
        size: 100, //medium column
      },
      {
        accessorKey: "invoice_date",
        header: "Data fattura",
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return row.original.invoice_date ? moment(row.original.invoice_date).format("DD/MM/YYYY") : "-";
          }else{
            return "-";
          }
        },
        enableColumnFilter: true,
        filterVariant: "date",
        enableSorting: false,
      },
      {
        accessorKey: "first_transaction_date",
        header: "Data inizio vendita",
        Cell: ({ row }) => {
          // Ordina le transazioni per data e prendi la prima
          const firstTransaction = row.original.first_transaction_date;

          return firstTransaction ? moment(firstTransaction).format("DD/MM/YYYY") : "-";
        },
        enableColumnFilter: true,
        filterVariant: "date",
        enableSorting: false,
      },
      {
        accessorKey: "last_transaction_date",
        header: "Data chiusura vendita",
        Cell: ({ row }) => {
          // Mostra l'ultima transazione solo se pre_order_target è 1
          if (row.original.pre_order_target === 1) {
            const lastTransaction = row.original.last_transaction_date;

            return lastTransaction ? moment(lastTransaction).format("DD/MM/YYYY") : "-";
          }
          return "-";
        },
        enableColumnFilter: true,
        filterVariant: "date",
        enableSorting: false,
      },
      {
        accessorKey: "payment_date",
        header: "Data pagamento",
        Cell: ({ row }) => (row.original.payment_date ? moment(row.original.payment_date).format("DD/MM/YYYY") : "-"),
        enableColumnFilter: true,
        filterVariant: "date",
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Azione",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => {
          if(row.original.type == "lot"){
            return ;
          }else{
            const payments = row.original.lots || [];
          const allLotsPaid =  payments.every(lot => lot.paid === true || lot.payment_id);
          return (
            <div className="d-flex flex-column gap-2 align-items-center">
              {/* Pulsante Paga: solo se non ci sono pagamenti */}
              {payments.length > 0 && !allLotsPaid ? (
                <button className="btn btn-success btn-sm w-100" onClick={() => handlePaymentClick(row.original)} title="Effettua pagamento">
                  Paga
                </button>
              ) : null}

              {/* Pulsante Modifica: sempre visibile */}
              <a href={`${config.baseUrl}itemAdd/${row.original.id}`} className="btn btn-primary btn-sm w-100" title="Dettaglio item">
                Modifica
              </a>
            </div>
          );
          }
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const renderTopToolbarCustomActions = () => (
    <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
      {csvLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status" aria-hidden="true"></div>
        </div>
      ) : csvData.length > 0 ? (
        <CSVLink data={csvData} filename={`pagamenti_${moment().format("YYYY-MM-DD")}.csv`} className="btn btn-primary">
          Scarica CSV
        </CSVLink>
      ) : (
        <Button color="primary" onClick={handleCsvDownload} variant="contained" style={{ backgroundColor: "#1976d2" }}>
          Carica CSV
        </Button>
      )}
    </Box>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    enableExporting: true,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableToolbarInternalActions: true,
    positionToolbarAlertBanner: "bottom",
    muiTableBodyRowProps: ({ row }) => ({
      //conditionally style selected rows
      sx: {
        backgroundColor: row.original.type === "lot" ? "#29303e !important" : ""
      }
    }),
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    enableExpanding: true,
    getSubRows: (originalRow) => originalRow.lotsRows, //default, can customize
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    renderTopToolbarCustomActions,
    muiTableBodyProps: {
      sx: {
        "& tr.MuiTableRow-root.Mui-expanded": {
          backgroundColor: "#ffffff",
          "& td": {
            color: "#000000",
          },
        },
      },
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        enableResizing: true, //allow resizing
        size: 100, //make the expand column wider
      },
      
    },
    
    
    
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Lista Pagamenti</h4>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default Pagamenti;
