import React, { useState, useEffect } from "react";
import config from "../coreFIles/config";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { getItemLotsByItemIdAction, createItemLotAction, updateItemLotAction, deleteItemLotAction, payItemLotAction } from "../Action/action";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReceiptDocument } from "../utils/ReceiptTemplate";

const WarehouseLabels = ({ setForm, suppliers, warehouses, itemId, pre_order_target }) => {
  // Stato locale per gestire i dettagli del magazzino
  const [warehouseDetails, setWarehouseDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savingLotId, setSavingLotId] = useState(null);
  const [deletingLotId, setDeletingLotId] = useState(null);

  // Funzione per ottenere la prossima lettera dell'alfabeto
  const getNextLetter = (currentDetails) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const usedLetters = new Set(currentDetails.map((detail) => detail.custom_id));

    for (let letter of alphabet) {
      if (!usedLetters.has(letter)) {
        return letter;
      }
    }
    return ""; // Nel caso improbabile che tutte le 26 lettere siano utilizzate
  };

  // Carica i lotti dell'item all'avvio
  useEffect(() => {
    if (itemId) {
      fetchItemLots();
    } else {
      // Se non c'è un itemId, inizializza con un lotto vuoto con la lettera A
      setWarehouseDetails([
        {
          id: "",
          custom_id: "A",
          description: "",
          warehouse_id: "",
          supplier_id: "",
          price: "",
          payment_type: "AUTOMATIC",
          paid: false,
          isNew: true,
        },
      ]);
    }
  }, [itemId]);

  // Aggiorna il form principale quando cambiano i dettagli
  useEffect(() => {
    setForm((prev) => ({ ...prev, warehouse_details: warehouseDetails }));
  }, [warehouseDetails, setForm]);

  // Funzione per caricare i lotti dell'item
  const fetchItemLots = async () => {
    try {
      setLoading(true);
      const response = await getItemLotsByItemIdAction(itemId);

      if (response.success) {
        // Se non ci sono lotti, inizializza con un lotto vuoto
        if (response.data.length === 0) {
          setWarehouseDetails([
            {
              id: "",
              custom_id: "A",
              description: "",
              warehouse_id: "",
              supplier_id: "",
              price: "",
              payment_type: "AUTOMATIC",
              paid: false,
              isNew: true,
            },
          ]);
        } else {
          setWarehouseDetails(
            response.data.map((lot) => ({
              ...lot,
              isNew: false,
              invoice_date: lot.invoice_date ? new Date(lot.invoice_date).toLocaleDateString("it-IT") : "",
            }))
          );
        }
      } else {
        toast.error("Errore nel caricamento dei lotti");
      }
    } catch (error) {
      console.error("Error fetching item lots:", error);
      toast.error("Errore nel caricamento dei lotti");
    } finally {
      setLoading(false);
    }
  };

  // Funzione per aggiornare un dettaglio specifico
  const updateDetail = (index, field, value) => {
    const newDetails = [...warehouseDetails];
    const oldDetail = newDetails[index];
    newDetails[index] = { ...oldDetail, [field]: value };

    // Se stiamo cambiando il fornitore, resettiamo i campi relativi alla fattura
    if (field === "supplier_id") {
      const wasCompany = isCompanySupplier(oldDetail.supplier_id);
      const isCompany = isCompanySupplier(value);

      if (wasCompany && !isCompany) {
        // Se il nuovo fornitore non è company, resettiamo i campi fattura
        newDetails[index] = {
          ...newDetails[index],
          invoice: null,
          invoice_number: "",
          invoice_date: "",
        };
      }
    }

    setWarehouseDetails(newDetails);
  };

  // Funzione per confermare il pagamento
  const confirmPayment = async (index) => {
    const lot = warehouseDetails[index];

    try {
      setSavingLotId(lot.id); // Usa savingLotId per mostrare lo spinner

      // Chiama l'API per il pagamento
      const response = await payItemLotAction(lot.id);

      if (response.success) {
        toast.success("Pagamento effettuato con successo");
        // Aggiorna la lista dei lotti dopo il pagamento
        const updatedLots = await getItemLotsByItemIdAction(itemId);
        if (updatedLots.success) {
          setWarehouseDetails(
            updatedLots.data.map((lot) => ({
              ...lot,
              invoice_date: lot.invoice_date ? new Date(lot.invoice_date).toLocaleDateString("it-IT") : "",
              isNew: false,
            }))
          );
        }
      } else {
        toast.error(response.message || "Errore durante il pagamento");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      toast.error("Errore durante il pagamento");
    } finally {
      setSavingLotId(null);
    }
  };

  // Funzione per trovare il nome del fornitore in base all'ID
  const getSupplierName = (supplierId) => {
    const supplier = suppliers.find((s) => s.id === supplierId);
    return supplier ? supplier.name : "-";
  };

  // Funzione per verificare se il fornitore è di tipo company
  const isCompanySupplier = (supplierId) => {
    if (!supplierId) return false;
    const supplier = suppliers.find((s) => s.id == supplierId);
    console.log(supplierId);
    console.log(supplier);
    console.log(suppliers);
    return supplier?.type === "company";
  };

  // Funzione per determinare se mostrare i dettagli di pagamento
  const shouldShowPaymentDetails = (detail) => {
    // Mostra i dettagli se:
    // 1. È un pagamento manuale (indipendentemente dallo stato di pagamento)
    // 2. È un pagamento automatico E è stato pagato o è in attesa di conferma
    // 3. È un pagamento automatico con payment_id valorizzato (in attesa di conferma)
    return detail.payment_type === "MANUAL" || (detail.payment_type === "AUTOMATIC" && (detail.paid || detail.payment_id));
  };

  // Funzione per determinare se un pagamento è in attesa di conferma
  const isPaymentPending = (detail) => {
    // Un pagamento in attesa di conferma è sempre di tipo automatico,
    // ha un payment_id valorizzato, ma non è ancora stato pagato
    return detail.payment_type === "AUTOMATIC" && detail.payment_id && !detail.paid;
  };

  // Funzione per salvare un lotto
  const saveLot = async (index) => {
    try {
      const lot = warehouseDetails[index];
      setSavingLotId(lot.id || `new-${index}`);

      // Validazione dei campi obbligatori base
      if (!lot.supplier_id || !lot.price) {
        toast.error("Fornitore e prezzo sono campi obbligatori");
        setSavingLotId(null);
        return;
      }

      // Validazione campi obbligatori per pagamento manuale
      if (lot.payment_type === "MANUAL") {
        if (!lot.bank_name) {
          toast.error("Per i pagamenti manuali, Banca è un campo obbligatorio");
          setSavingLotId(null);
          return;
        }
      }

      /*       // Validazione campi obbligatori per fornitori company
      if (isCompanySupplier(lot.supplier_id)) {
        if (!lot.invoice_number || !lot.invoice_date) {
          toast.error("Per i fornitori di tipo azienda, Numero fattura e Data fattura sono campi obbligatori");
          setSavingLotId(null);
          return;
        }
      } */

      // Prepara i dati per l'invio
      const formData = new FormData();
      formData.append("item_id", itemId);
      formData.append("supplier_id", lot.supplier_id);
      if (lot.warehouse_id) formData.append("warehouse_id", lot.warehouse_id);
      formData.append("description", lot.description || "");
      formData.append("price", lot.price);
      formData.append("custom_id", lot.custom_id || "");
      formData.append("payment_type", lot.payment_type || "AUTOMATIC");
      formData.append("paid", lot.paid ? "true" : "false");
      // Se il tipo di pagamento è manuale, imposta paid a true
      if (lot.payment_type === "MANUAL") {
        formData.append("paid", "true");
        if (pre_order_target === 0) {
          formData.append("rebalace", "true");
        }
      }
      if (lot.bank_name) formData.append("bank_name", lot.bank_name);
      if (lot.payment_id) formData.append("payment_id", lot.payment_id);
      if (lot.payment_date) formData.append("payment_date", lot.payment_date);
      if (lot.rebalanced !== undefined) formData.append("rebalanced", lot.rebalanced ? "true" : "false");
      if (lot.invoice_number) formData.append("invoice_number", lot.invoice_number);
      if (lot.invoice_date) formData.append("invoice_date", lot.invoice_date);

      // Gestione dei file
      if (lot.contabile && lot.contabile instanceof File) {
        formData.append("contabile", lot.contabile);
      } else if (lot.contabile && typeof lot.contabile === "string") {
        formData.append("contabile", lot.contabile);
      }

      if (lot.warehouse_image && lot.warehouse_image instanceof File) {
        formData.append("warehouse_image", lot.warehouse_image);
      } else if (lot.warehouse_image && typeof lot.warehouse_image === "string") {
        formData.append("warehouse_image", lot.warehouse_image);
      }

      if (lot.bolla_consegna && lot.bolla_consegna instanceof File) {
        formData.append("bolla_consegna", lot.bolla_consegna);
      } else if (lot.bolla_consegna && typeof lot.bolla_consegna === "string") {
        formData.append("bolla_consegna", lot.bolla_consegna);
      }

      if (lot.invoice && lot.invoice instanceof File) {
        formData.append("invoice", lot.invoice);
      } else if (lot.invoice && typeof lot.invoice === "string") {
        formData.append("invoice", lot.invoice);
      }

      let response;
      if (lot.isNew || !lot.id) {
        // Crea un nuovo lotto
        response = await createItemLotAction(Object.fromEntries(formData));
      } else {
        // Aggiorna un lotto esistente
        // Rimuovi bolla_consegna dal FormData prima di inviare
        response = await updateItemLotAction(lot.id, Object.fromEntries(formData));
      }

      if (response.success) {
        toast.success(lot.isNew ? "Lotto creato con successo" : "Lotto aggiornato con successo");

        // Aggiorna lo stato locale con i dati aggiornati
        const newDetails = [...warehouseDetails];
        newDetails[index] = {
          ...response.data,
          isNew: false,
          invoice_date: response.data.invoice_date ? new Date(response.data.invoice_date).toLocaleDateString("it-IT") : "",
        };
        setWarehouseDetails(newDetails);
      } else {
        toast.error(lot.isNew ? "Errore nella creazione del lotto" : "Errore nell'aggiornamento del lotto");
      }
    } catch (error) {
      console.error("Error saving lot:", error);
      toast.error("Errore nel salvataggio del lotto");
    } finally {
      setSavingLotId(null);
    }
  };

  // Funzione per eliminare un lotto
  const deleteLot = async (index) => {
    const lot = warehouseDetails[index];

    // Se è un nuovo lotto non ancora salvato, rimuovilo semplicemente dallo stato
    if (lot.isNew || !lot.id) {
      const newDetails = warehouseDetails.filter((_, i) => i !== index);
      setWarehouseDetails(
        newDetails.length > 0
          ? newDetails
          : [
              {
                id: "",
                custom_id: "",
                description: "",
                warehouse_id: "",
                supplier_id: "",
                price: "",
                payment_type: "AUTOMATIC",
                paid: false,
                isNew: true,
              },
            ]
      );
      return;
    }

    // Chiedi conferma prima di eliminare
    Swal.fire({
      title: "Sei sicuro?",
      text: "Questa azione non può essere annullata!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sì, elimina!",
      cancelButtonText: "Annulla",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setDeletingLotId(lot.id);

          const response = await deleteItemLotAction(lot.id);

          if (response.success) {
            toast.success("Lotto eliminato con successo");

            // Rimuovi il lotto dallo stato locale
            const newDetails = warehouseDetails.filter((_, i) => i !== index);
            setWarehouseDetails(
              newDetails.length > 0
                ? newDetails
                : [
                    {
                      id: "",
                      custom_id: "",
                      description: "",
                      warehouse_id: "",
                      supplier_id: "",
                      price: "",
                      payment_type: "AUTOMATIC",
                      paid: false,
                      isNew: true,
                    },
                  ]
            );
          } else {
            toast.error("Errore nell'eliminazione del lotto");
          }
        } catch (error) {
          console.error("Error deleting lot:", error);
          toast.error("Errore nell'eliminazione del lotto");
        } finally {
          setDeletingLotId(null);
        }
      }
    });
  };

  // Funzione per aggiungere un nuovo lotto
  const addNewLot = () => {
    const nextLetter = getNextLetter(warehouseDetails);
    setWarehouseDetails([
      ...warehouseDetails,
      {
        id: "",
        custom_id: nextLetter,
        description: "",
        warehouse_id: "",
        supplier_id: "",
        price: "",
        payment_type: "AUTOMATIC",
        paid: false,
        isNew: true,
      },
    ]);
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  return (
    <div className="warehouse-labels-container">
      {warehouseDetails.map((detail, index) => (
        <div
          className="warehouse-label-item mb-3"
          key={detail.id || `new-${index}`}
          style={{
            backgroundColor: "#131720",
            borderRadius: "8px",
            padding: "15px",
            position: "relative",
            border: "1px solid #ffffff1f",
          }}
        >
          <div
            className="label-number"
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              backgroundColor: "#007bff",
              color: "white",
              borderRadius: "50%",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {index + 1}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-2">
                <label className="col-form-label small mb-1">ID Etichetta</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={detail.custom_id || ""}
                  onChange={(e) => updateDetail(index, "custom_id", e.target.value)}
                  placeholder="ID Etichetta"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-2">
                <label className="col-form-label small mb-1">Descrizione lotto</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={detail.description || ""}
                  onChange={(e) => updateDetail(index, "description", e.target.value)}
                  placeholder="Descrizione lotto"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-2">
                <label className="col-form-label small mb-1">Fornitore</label>
                {!detail.paid ? (
                  <select className="form-select form-select-sm" value={detail.supplier_id || ""} onChange={(e) => updateDetail(index, "supplier_id", e.target.value)}>
                    <option value="">Seleziona fornitore</option>
                    {suppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="form-control-plaintext form-control-sm text-light mb-0 ps-2" style={{ backgroundColor: "#1e2530", borderRadius: "4px", padding: "0.25rem 0" }}>
                    {getSupplierName(detail.supplier_id) || "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-2">
                <label className="col-form-label small mb-1">Prezzo acquisto (€)</label>
                {!detail.paid ? (
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    value={detail.price || ""}
                    onChange={(e) => updateDetail(index, "price", e.target.value)}
                    placeholder="0.00"
                    step="0.01"
                    min="0"
                  />
                ) : (
                  <p className="form-control-plaintext form-control-sm text-light mb-0 ps-2" style={{ backgroundColor: "#1e2530", borderRadius: "4px", padding: "0.25rem 0" }}>
                    {parseFloat(detail.price).toFixed(2)} €
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-group mb-2">
                <label className="col-form-label small mb-1">Magazzino</label>
                <select className="form-select form-select-sm" value={detail.warehouse_id || ""} onChange={(e) => updateDetail(index, "warehouse_id", e.target.value)}>
                  <option value="">Seleziona magazzino</option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {detail.warehouse_id && (
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Bolla magazzino</label>
                  <input type="file" className="form-control form-control-sm" accept=".pdf" onChange={(e) => updateDetail(index, "bolla_consegna", e.target.files[0])} />
                  {detail.bolla_consegna && typeof detail.bolla_consegna === "string" && (
                    <div className="mt-1">
                      <a href={config.apiUrl + "/itemPdf/" + detail.bolla_consegna} target="_blank" rel="noopener noreferrer" className="small text-info">
                        <i className="fa fa-file-pdf"></i> Visualizza documento
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Immagine magazzino</label>
                  <input type="file" className="form-control form-control-sm" accept="image/*" onChange={(e) => updateDetail(index, "warehouse_image", e.target.files[0])} />
                  {detail.warehouse_image && typeof detail.warehouse_image === "string" && (
                    <div className="mt-1">
                      <a href={config.imageUrl + detail.warehouse_image} target="_blank" rel="noopener noreferrer" className="small text-info">
                        <i className="fa fa-image"></i> Visualizza immagine
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Campi fattura per fornitori di tipo company */}
          {detail.supplier_id && isCompanySupplier(detail.supplier_id) && (
            <div className="row mt-2">
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Numero Fattura</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={detail.invoice_number || ""}
                    onChange={(e) => updateDetail(index, "invoice_number", e.target.value)}
                    placeholder="Numero fattura"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Data Fattura </label>
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    value={detail.invoice_date ? detail.invoice_date.split('/').reverse().join('-') : ''}
                    onChange={(e) => {
                      const date = e.target.value;
                      const [year, month, day] = date.split('-');
                      updateDetail(index, "invoice_date", `${day}/${month}/${year}`);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Fattura PDF</label>
                  <input type="file" className="form-control form-control-sm" accept=".pdf" onChange={(e) => updateDetail(index, "invoice", e.target.files[0])} />
                  {detail.invoice && typeof detail.invoice === "string" && (
                    <div className="mt-1">
                      <a href={config.apiUrl + "/itemPdf/" + detail.invoice} target="_blank" rel="noopener noreferrer" className="small text-info">
                        <i className="fa fa-file-pdf"></i> Visualizza fattura
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="row mt-3">
            <div className="col-md-12">
              {!detail.paid && !isPaymentPending(detail) ? (
                <div className="mb-3">
                  <label className="col-form-label small mb-1">Metodo di pagamento</label>
                  <div className="d-flex">
                    <div className="form-check me-4">
                      <input
                        type="radio"
                        className="form-check-input"
                        id={`automatic-payment-${index}`}
                        name={`payment-type-${index}`}
                        checked={detail.payment_type === "AUTOMATIC" || !detail.payment_type}
                        onChange={() => updateDetail(index, "payment_type", "AUTOMATIC")}
                      />
                      <label className="form-check-label small" htmlFor={`automatic-payment-${index}`}>
                        Pagamento automatico
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id={`manual-payment-${index}`}
                        name={`payment-type-${index}`}
                        checked={detail.payment_type === "MANUAL"}
                        onChange={() => updateDetail(index, "payment_type", "MANUAL")}
                      />
                      <label className="form-check-label small" htmlFor={`manual-payment-${index}`}>
                        Pagamento manuale
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center mb-2">
                  <span className={`badge ${detail.payment_type === "MANUAL" ? "bg-primary" : detail.paid ? "bg-success" : "bg-warning"} me-2`}>
                    {detail.payment_type === "MANUAL" || detail.paid ? <i className="fa fa-check-circle me-1"></i> : <i className="fa fa-clock-o me-1"></i>}
                    {detail.payment_type === "MANUAL" ? "Pagato manualmente" : detail.paid ? "Pagato automaticamente" : "Pagamento in attesa di conferma"}
                  </span>
                  <span className="small text-light">{detail.payment_date ? `il ${new Date(detail.payment_date).toLocaleDateString()}` : ""}</span>
                </div>
              )}
            </div>
          </div>

          {shouldShowPaymentDetails(detail) && (
            <div
              className="row mt-2 payment-details"
              style={{
                backgroundColor: detail.paid ? (detail.payment_type === "MANUAL" ? "#1a2a3a" : "#1a3a2a") : isPaymentPending(detail) ? "#2a2a1a" : "#1e2530",
                padding: "10px",
                borderRadius: "5px",
                border: detail.paid ? (detail.payment_type === "MANUAL" ? "1px solid #0d6efd" : "1px solid #28a745") : isPaymentPending(detail) ? "1px solid #ffc107" : "none",
              }}
            >
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Banca</label>
                  {detail.payment_type === "MANUAL" && !detail.paid ? (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={detail.bank_name || ""}
                      onChange={(e) => updateDetail(index, "bank_name", e.target.value)}
                      placeholder="Nome banca"
                    />
                  ) : (
                    <p className="form-control-plaintext form-control-sm text-light mb-0">{detail.bank_name || "-"}</p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Data Pagamento</label>
                  {detail.payment_type === "MANUAL" && !detail.paid ? (
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      value={detail.payment_date || ""}
                      onChange={(e) => updateDetail(index, "payment_date", e.target.value)}
                    />
                  ) : (
                    <p className="form-control-plaintext form-control-sm text-light mb-0">{detail.payment_date ? new Date(detail.payment_date).toLocaleDateString() : "-"}</p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">ID Transazione</label>
                  {detail.payment_type === "MANUAL" && !detail.paid ? (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={detail.payment_id || ""}
                      onChange={(e) => updateDetail(index, "payment_id", e.target.value)}
                      placeholder="ID della transazione"
                    />
                  ) : (
                    <p className="form-control-plaintext form-control-sm text-light mb-0">{detail.payment_id || "-"}</p>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-2">
                  <label className="col-form-label small mb-1">Contabile</label>
                  <input type="file" className="form-control form-control-sm" onChange={(e) => updateDetail(index, "contabile", e.target.files[0])} />
                  {detail.contabile && typeof detail.contabile === "string" && (
                    <div className="mt-1">
                      <a href={detail.contabile} target="_blank" rel="noopener noreferrer" className="small text-info">
                        <i className="fa fa-file-pdf"></i> Visualizza contabile
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!detail.paid && detail.payment_type === "AUTOMATIC" && detail.id && !detail.payment_id && (
            <div className="row mt-3">
              <div className="col-12">
                <button type="button" className="btn btn-success btn-sm" onClick={() => confirmPayment(index)} disabled={savingLotId === detail.id}>
                  {savingLotId === detail.id ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                      Pagamento in corso...
                    </>
                  ) : (
                    <>
                      <i className="fa fa-credit-card me-1"></i> Paga
                    </>
                  )}
                </button>
              </div>
            </div>
          )}

          {!isCompanySupplier(detail.supplier_id) && (
            <div className="row mt-3">
              <div className="col-12">
                <PDFDownloadLink
                  document={
                    <ReceiptDocument
                      lot={detail}
                      supplierDetails={suppliers.find((s) => s.id === detail.supplier_id)}
                    />
                  }
                  fileName={`ricevuta_${detail.id}.pdf`}
                >
                  {({ loading }) => (
                    <button type="button" className="btn btn-secondary" disabled={!Boolean(detail.paid) || loading}>
                      {loading ? "Generazione..." : "Scarica Ricevuta"}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
          )}

          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-between">
              <button type="button" className="btn btn-primary btn-sm" onClick={() => saveLot(index)} disabled={savingLotId === detail.id || savingLotId === `new-${index}`}>
                {savingLotId === detail.id || savingLotId === `new-${index}` ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    Salvataggio...
                  </>
                ) : (
                  <>
                    <i className="fa fa-save me-1"></i>
                    {detail.isNew || !detail.id ? "Salva nuovo lotto" : "Aggiorna lotto"}
                  </>
                )}
              </button>

              {!detail.paid && !isPaymentPending(detail) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteLot(index)}
                  disabled={deletingLotId === detail.id}
                  style={{ width: "auto", height: "auto", padding: "0.25rem 0.5rem" }}
                >
                  {deletingLotId === detail.id ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                    <>
                      <i className="fa fa-trash me-1"></i> Elimina
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button type="button" className="btn btn-primary" onClick={addNewLot}>
          <i className="fa fa-plus"></i> Aggiungi Etichetta magazzino
        </button>
        <div className="mt-2">
          <span className="validationErr">{/* Qui andrebbe validatioError.warehouse_detailsError */}</span>
        </div>
      </div>
    </div>
  );
};

export default WarehouseLabels;
