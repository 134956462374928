import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { getSettingsAction, updateSettingsAction } from '../Action/action';
import Swal from 'sweetalert2';

const OTP_PROVIDERS = {
  TWILIO: 'twilio',
  SMS_API: 'sms_api',
  SPOKI: 'spoki'
};

const Settings = () => {
  const [otpProvider, setOtpProvider] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await getSettingsAction();
      if (response.success) {
        setOtpProvider(response.data.settings.otp_provider || '');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.msg || 'Error fetching settings'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error fetching settings'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const response = await updateSettingsAction({ otp_provider: otpProvider });
      if (response.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Settings updated successfully'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.msg || 'Error updating settings'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error updating settings'
      });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Settings</h3>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Settings</h3>
                </div>
              </div>
            </div>
            <section className="content pt-0">
              <div className="row">
                <div className="col-xl-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Impostazioni OTP</h4>
                    </div>
                    <div className="box-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-4">
                              <label className="form-label fw-bold mb-2">OTP Provider</label>
                              <div className="input-group mb-3">
                                <select
                                  className="form-control"
                                  value={otpProvider}
                                  onChange={(e) => setOtpProvider(e.target.value)}
                                >
                                  {Object.entries(OTP_PROVIDERS).map(([key, value]) => (
                                    <option key={value} value={value}>
                                      {key}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <small className="text-muted">
                                Scegli il provider che verrà utilizzato per l'invio dei codici OTP
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-success"
                              disabled={saving}
                            >
                              {saving ? (
                                <>
                                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                  Salvataggio...
                                </>
                              ) : (
                                <>
                                  <i data-feather="save" className="me-2"></i>
                                  Salva Impostazioni
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Settings; 